<script>
export default {
  inheritAttrs: false,
  props: {
    training: {
      type: Object,
      required: true,
    },
  },
  computed: {
    salesUrl() {
      const { protocol } = window.location;
      return `${protocol}${this.$store.getters['auth/url']}/formations/${this.training.slug}`;
    },
    shareTitle() {
      return this.$t('share.training.title', { title: this.training.name });
    },
  },
};
</script>
<template>
  <b-dropdown ref="dropdown" v-bind="$attrs">
    <template #trigger>
      <slot />
    </template>
    <b-dropdown-item
      v-clipboard:copy="salesUrl"
      v-clipboard:success="() => $copySuccess()"
      v-clipboard:error="() => $copyError()"
      aria-role="menuitem"
    >
      <b-icon pack="far" size="is-medium" icon="clipboard" />
      Copier le lien de la formation
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <ShareNetwork
        network="email"
        :url="salesUrl"
        :title="shareTitle"
        :description="shareTitle"
      >
        <b-icon pack="far" size="is-medium" icon="envelope" />
        Partager par email
      </ShareNetwork>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <ShareNetwork
        network="facebook"
        :url="salesUrl"
        :title="shareTitle"
        :description="shareTitle"
        :quote="shareTitle"
      >
        <b-icon pack="fab" size="is-medium" icon="facebook" />
        Partager sur Facebook
      </ShareNetwork>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <ShareNetwork
        network="linkedin"
        :url="salesUrl"
        :title="shareTitle"
      >
        <b-icon pack="fab" size="is-medium" icon="linkedin" />
        Partager sur LinkedIn
      </ShareNetwork>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <ShareNetwork
        network="pinterest"
        :url="salesUrl"
        :title="shareTitle"
      >
        <b-icon pack="fab" size="is-medium" icon="pinterest" />
        Partager sur Pinterest
      </ShareNetwork>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <ShareNetwork
        network="whatsapp"
        :url="salesUrl"
        :title="shareTitle"
        :description="shareTitle"
      >
        <b-icon pack="fab" size="is-medium" icon="whatsapp" />
        Partager sur WhatsApp
      </ShareNetwork>
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <ShareNetwork
        network="telegram"
        :url="salesUrl"
        :title="shareTitle"
        :description="shareTitle"
      >
        <b-icon pack="fab" size="is-medium" icon="telegram" />
        Partager sur Telegram
      </ShareNetwork>
    </b-dropdown-item>
  </b-dropdown>
</template>
