<script>
import ENUMS from '@shared/messages/enums.json';
import trainingsMixin from '@app/mixins/trainings';
import TrainingShareDropdown from '@app/components/TrainingShareDropdown.vue';

export default {
  mixins: [trainingsMixin],
  components: { TrainingShareDropdown },
  analytics: { skip: true },
  head() {
    if (!this.currentTraining) {
      return null;
    }

    setTimeout(() => this.$gtag.pageView(), 200);

    return {
      titleTemplate: `%s - ${this.currentTraining.name} | Teachizy`,
      afterNavigation: () => this.$gtag.pageView(),
    };
  },
  messages: {
    status: ENUMS.trainingStatus,
    statusClass: ENUMS.trainingStatusClass,
  },
  data: () => ({ isLoading: false }),
  created() {
    this.isLoading = true;
    this.$store.dispatch('trainings/find', this.$route.params.uuid)
      .catch(() => this.$errorHandlers.showPage(404, 'Cette page n\'existe pas'))
      .finally(() => (this.isLoading = false));
  },
  destroyed() {
    this.$store.commit('trainings/setCurrent', null);
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    salesURL() {
      return `${this.$store.getters['auth/url']}/formations/${this.currentTraining.slug}`;
    },
    schoolUrl() {
      const q = `?p=${this.$store.state.auth.previewToken}`;
      const url = this.$store.getters['auth/url'];
      return `${url}/mon-espace/formations/${this.currentTraining.slug}${q}`;
    },
  },
  methods: {
    onViewDraft() {
      const p = `?p=${this.$store.state.auth.previewToken}`;
      this.$buefy.dialog.confirm({
        title: 'Formation en brouillon !',
        message: `
          Votre formation est en brouillon. <br>
          Le lien auquel vous allez accéder est un lien de prévisualisation :
          <strong>ne le partagez pas, personne d'autre n'y aura accès</strong>
        `,
        type: 'is-warning',
        focusOn: 'cancel',
        onConfirm: () => window.open(`${this.salesURL}${p}`),
      });
    },
    updateStatus(status) {
      if (this.currentTraining.status === status) {
        return;
      }

      if (['UNLISTED', 'HIDDEN'].includes(status) && this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro();
        return;
      }

      const loader = this.$buefy.loading.open();
      this.$store.dispatch('trainings/update', {
        uuid: this.currentTraining.uuid,
        training: { status },
        isCurrent: true,
      })
        .catch(this.handleStripeError)
        .finally(() => loader.close());
    },
    editTitle() {
      this.$buefy.dialog.prompt({
        title: 'Modifier le titre',
        inputAttrs: { value: this.currentTraining.name, maxlength: 128 },
        confirmText: 'Valider',
        onConfirm: (name) => {
          const loader = this.$buefy.loading.open();
          this.$store
            .dispatch('trainings/update', {
              uuid: this.currentTraining.uuid,
              training: { name },
              isCurrent: true,
            })
            .finally(() => loader.close());
        },
      });
    },
  },
};
</script>

<template>
  <section class="page">
    <b-loading v-if="!currentTraining || isLoading" active />
    <template v-else>
      <p>
        <router-link :to="{name: 'trainings'}">
          <b-icon size="is-small" icon="chevron-left" />
          Retour aux formations
        </router-link>
      </p>

      <div class="columns is-vcentered mt-0">
        <div class="column">
          <h1 class="title is-3">
            {{ currentTraining.name }}
            <b-button
              type="is-text"
              class="tilcomp_quickedit py-0 h-auto valign-middle"
              icon-left="pencil-alt"
              size="is-small"
              title="Modifier le titre"
              @click.stop.prevent="editTitle"
            />
            <span class="tag is-light mr-3">
              <b-icon
                  class="mr-1 is-size-9"
                  icon="circle"
                  :type="$options.messages.statusClass[currentTraining.status]"
                />
              {{ $options.messages.status[currentTraining.status] }}
            </span>
          </h1>
        </div>

        <div class="column is-narrow">
          <b-dropdown position="is-top-left" aria-role="menu" append-to-body expanded>
            <template #trigger>
              <b-button tag="a" type="is-text" icon-left="eye" expanded>
                Aperçu de la page
              </b-button>
            </template>
            <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
              <b-button
                v-if="currentTraining.status == 'DRAFT'"
                type="is-text"
                tag="a"
                @click.prevent="onViewDraft">
                Page visiteur
              </b-button>
              <b-button
                v-else
                tag="a"
                :href="salesURL"
                target="_blank"
                type="is-text">
                Page visiteur
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
              <a :href="schoolUrl" target="_blank">
                Page apprenant
              </a>
            </b-dropdown-item>
          </b-dropdown>
          <div class="is-flex">
            <b-dropdown class="is-block" position="is-bottom-left" aria-role="menu">
              <template #trigger>
                <b-button icon-right="chevron-down" expanded>
                  <b-icon
                    class="mr-1 is-size-9"
                    icon="circle"
                    :type="$options.messages.statusClass[currentTraining.status]"
                  />
                  {{ $options.messages.status[currentTraining.status] }}
                </b-button>
              </template>
              <b-dropdown-item aria-role="menuitem" @click="updateStatus('DRAFT')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-warning" class="is-size-9" />
                  En brouillon
                </p>
                <p class="is-ize-7">
                  Votre formation est UNIQUEMENT <br>
                  visible par vous en mode "Aperçu"
                </p>
              </b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" @click="updateStatus('PUBLISHED')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-success" class="is-size-9" />
                  Publiée
                </p>
                <p class="is-ize-7">
                  Votre formation est visible par tous
                </p>
              </b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" @click="updateStatus('UNLISTED')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-info" class="is-size-9" />
                  Non répertoriée
                  <AppFeatureBadge class="ml-2" position="is-top" />
                </p>
                <p class="is-ize-7">
                  Votre formation est UNIQUEMENT <br>
                  visible par les personnes ayant le lien<br>
                  ainsi que par TOUS vos apprenants
                </p>
              </b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" @click="updateStatus('HIDDEN')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-dark" class="is-size-9" />
                  Cachée
                  <AppFeatureBadge class="ml-2" position="is-top" />
                </p>
                <p class="is-ize-7">
                  Votre formation est UNIQUEMENT <br>
                  visible par les personnes ayant le lien <br>
                  et les apprenants l'ayant acquis
                </p>
              </b-dropdown-item>
              <!-- <b-dropdown-item aria-role="menuitem" @click="updateStatus('PRE_SALE')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-success" class="is-size-9" />
                  En Prévente
                </p>
                <p class="is-ize-7">
                  Votre formation est achetable MAIS, <br>
                  les apprenants n'y auront qu'à la sortie.
                </p>
              </b-dropdown-item>
              <b-dropdown-item aria-role="menuitem" @click="updateStatus('PRE_LAUNCH')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-success" class="is-size-9" />
                  En Pré-inscriptions
                </p>
                <p class="is-ize-7">
                  Votre formation est ouverte aux inscriptions, <br>
                  MAIS les apprenants paieront (ou non) <br>
                  et n'y auront accès qu'à la sortie.
                </p>
              </b-dropdown-item> -->
              <b-dropdown-item aria-role="menuitem" @click="updateStatus('CLOSED')">
                <p class="is-size-7 has-text-weight-bold">
                  <b-icon icon="circle" type="is-danger" class="is-size-9" />
                  Désactivée
                </p>
                <p class="is-ize-7">
                  Votre formation est visible par tous MAIS <br>
                  les nouvelles inscriptions sont désactivées
                </p>
              </b-dropdown-item>
            </b-dropdown>

            <TrainingShareDropdown
              :training="currentTraining"
              position="is-bottom-left"
              aria-role="menu">
              <b-tooltip label="Partager la formation" position="is-left">
                <b-button icon-left="share-square" />
              </b-tooltip>
            </TrainingShareDropdown>
          </div>
        </div>
      </div>

      <div class="tabs mb-10">
        <ul>
          <router-link
            tag="li"
            :to="{name: 'training', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Contenu</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'training_settings', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Paramètres</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'training_price', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Prix</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'training_description', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Descriptions</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'training_customers', params: $route.params}"
            active-class="is-active"
          >
            <a>Apprenants</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'training_comments', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Commentaires</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'training_reporting', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Statistiques <AppFeatureBadge class="ml-1" position="is-left" /></a>
          </router-link>
        </ul>
      </div>
      <div>
        <router-view />
      </div>
    </template>
  </section>
</template>
